.cityFinder {
  font-family: 'graphik', Arial, sans-serif;
  margin: 20px auto 0;
  width: 80%;
  background-color: white;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 100%;
  padding: 20px 25px;
  border-radius: 10px;
  background-image: url('./img/bg.jpg');
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.25);

  :global(.gamma) & {
    width: 720px;
  }

  .twoColContainer {
    display: flex;
    align-items: center;

    .rightCol {
      flex: 30%;
      text-align: center;
      margin: 0 auto;

      img {
        max-height: 80px;
        max-width: 160px;
      }
    }

    .leftCol {
      flex: 70%;
      margin-right: 20px;
      font-style: italic;

      .identityMsg {
        font-size: 25px;
        width: 100%;
        color: #de2148;
        text-transform: uppercase;
        font-weight: bold;
        margin-top: 5px;
        margin-bottom: 15px;
        line-height: 1.2em;

        :global(.alpha) & {
          font-size: 20px;
        }
      }

      .city {
        align-items: center;
        display: grid;
        grid-template-columns: auto 1fr;
        width: 100%;
        margin-bottom: -5px;

        img {
          grid-row: 1 / 3;
          margin-right: 9px;
          vertical-align: middle;
          padding-bottom: 5px;
        }

        strong {
          font-size: 16px;
          text-transform: uppercase;
          margin-right: 9px;
        }

        .changeLocation {
          font-size: 13px;
          color: #0a50b0;
          cursor: pointer;
          font-style: normal;
          grid-row: 1 / 3;
          white-space: nowrap;
        }

        strong + .changeLocation {
          grid-row: initial;
        }
      }
    }
  }

  .selectContainer {
    margin-top: 20px;
  }
}

@media (max-width: 768px) {
  :global(.mol-mobile) {
    .cityFinder {
      width: 100%;
      padding: 10px 12px;

      .twoColContainer {
        .rightCol {
          flex: 25%;
          text-align: center;
          margin: 0 auto;

          img {
            max-height: 80px;
            max-width: none;
            width: 100px;
          }
        }

        .leftCol {
          flex: 75%;
          margin-right: 3px;

          .identityMsg {
            font-size: 1.2em;
            margin-bottom: 8px;
          }

          .city {
            margin-bottom: 0;

            img {
              width: 9px;
              margin-right: 5px;
            }

            strong {
              font-size: 1.1em;
              margin-right: 5px;
            }

            .changeLocation {
              font-size: 0.85em;
            }
          }
        }
      }

      .selectContainer {
        margin-top: 10px;
      }
    }
  }
}
