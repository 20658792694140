.container {
  margin: 0 0 15px;
}

.heading {
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 !important;
}

.headingText {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.body {
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0 0 10px 10px;
}

:global(.mol-mobile) {
  .heading {
    padding-top: 25.45%;
  }

  .heading.monday {
    background-image: url('./img/monday/banner-mobile@1x.png');
    padding-top: 26.8%;

    @media (min-resolution: 1.5dppx) {
      background-image: url('./img/monday/banner-mobile@2x.png');
    }

    @media (min-resolution: 2.5dppx) {
      background-image: url('./img/monday/banner-mobile@3x.png');
    }
  }

  .heading.today {
    background-image: url('./img/today/banner-mobile@1x.png');

    @media (min-resolution: 1.5dppx) {
      background-image: url('./img/today/banner-mobile@2x.png');
    }

    @media (min-resolution: 2.5dppx) {
      background-image: url('./img/today/banner-mobile@3x.png');
    }
  }

  .body {
    background-image: url('./img/body-bg@1x.jpg');
    padding: 10px 10px 20px;

    @media (min-resolution: 1.5dppx) {
      background-image: url('./img/body-bg@2x.jpg');
    }

    @media (min-resolution: 2.5dppx) {
      background-image: url('./img/body-bg@3x.jpg');
    }
  }
}

:global(.alpha) {
  .heading {
    padding-top: 19.6%;
  }

  .heading.monday {
    background-image: url('./img/monday/banner-alpha@1x.png');
    padding-top: 20.1%;

    @media (min-resolution: 1.5dppx) {
      background-image: url('./img/monday/banner-alpha@2x.png');
    }

    @media (min-resolution: 2.5dppx) {
      background-image: url('./img/monday/banner-alpha@3x.png');
    }
  }

  .heading.today {
    background-image: url('./img/today/banner-alpha@1x.png');

    @media (min-resolution: 1.5dppx) {
      background-image: url('./img/today/banner-alpha@2x.png');
    }

    @media (min-resolution: 2.5dppx) {
      background-image: url('./img/today/banner-alpha@3x.png');
    }
  }

  .body {
    background-image: url('./img/body-bg@2x.jpg');
    padding: 0 0 45px;

    @media (min-resolution: 1.5dppx) {
      background-image: url('./img/body-bg@3x.jpg');
    }
  }
}

:global(.gamma) {
  .heading {
    padding-top: 12.5%;
  }

  .heading.monday {
    background-image: url('./img/monday/banner-gamma@1x.png');
    padding-top: 13.3%;

    @media (min-resolution: 1.5dppx) {
      background-image: url('./img/monday/banner-gamma@2x.png');
    }

    @media (min-resolution: 2.5dppx) {
      background-image: url('./img/monday/banner-gamma@3x.png');
    }
  }

  .heading.today {
    background-image: url('./img/today/banner-gamma@1x.png');

    @media (min-resolution: 1.5dppx) {
      background-image: url('./img/today/banner-gamma@2x.png');
    }

    @media (min-resolution: 2.5dppx) {
      background-image: url('./img/today/banner-gamma@3x.png');
    }
  }

  .body {
    background-image: url('./img/body-bg@3x.jpg');
    padding: 35px 0 20px;

    :global(.vjs-video-container) {
      margin-left: auto;
      margin-right: auto;
      max-width: 727px;
    }
  }
}
